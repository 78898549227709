// 170mm = 100%
// 1.7mm = 1%

// 4mm = 20px
// 3mm = 15px
// 2mm = 10px
// 1mm = 5px

//  9pt = 16px
// 10pt = 18px
// 11pt = 20px
// 12pt = 22px
// 13pt = 24px
// 14pt = 26px

body.product{
    font-size: $font-size;
    line-height: auto;
    font-family: $font-light;
    
    .ninchar{
        font-family: 'NIN3X';
        font-weight: normal;
        font-style: normal;
        margin-right: 5px;
    }
    .content_img{
        width: 8.333%
    }
    .content-box{
        a{
            color: $color-blue;
            text-decoration: none;
            &.init-content{
                color: $color-black;
            }
        }
        .toc-inline{
            a {
                cursor: pointer;
                display: flex;
                color: $color-black;
                
                span {
                    display: inline-block;
                    flex: 100px 0 0;
                }
            }
        }
        
        /** DV ****************************************************************/
        .printstop .content{
            background: #ffff99;
            display: block;
            width: 148mm;
            padding: 5mm;
            border:1mm solid #ff9933;
            margin-top: 30mm;
            text-align:center;
            font-family: 'UniversLTStd Bold';
        }

        
        /********** TECONIA ***************************************************/
        
        p {
            font-size: $font-size;
            /*margin-bottom: 5px;*/
            /*margin-top: 5px*/
        }
        img {
            max-width: 100%;
            /*width: 8.333%;*/
            height: auto !important;
            
            .fleft {
                float:left;
            }
        }
        strong, b {
            font-family: $font-bold;
            font-weight: normal;
        }
        h1, h2, h3, h4, h5, h6, h7 {
            font-family: $font-bold;
            font-weight: normal;
            padding-bottom: 10px;
            padding-top: 5px;
            margin: 0;
            display: block;
            width: 100%;
            page-break-after: avoid;
        }

        h1,h2 {
            font-size: 24px;
        }
        h3,h4 {
            font-size: 20px;
        }
        h5,h6 {
            font-size: $font-size;
        }
        
        
        /* TABLES *************************************************************/
        table { 
            position: relative;
            font-weight: normal;
            width: 100%;
            border: none;
            
            &.table_25{
                width: 25%
            }
            &.table_50{
                width: 50%
            }
            &.table_75{
                width: 75%
            }
            &.table_100{
                width: 75%
            }
            
            td {
                vertical-align: top;
                border: none;
            }
            &.normaltext{
                width: 90%;
                width: calc(100% - 20px);
            }
        }
        .td_50{ 
            width:50px;
        }
        .table_border{
            border:none;
            border-collapse:collapse;
            /*width:100% !important; */
            border-bottom:1px solid $color-black !important;
            
            td, th{
                border:1px solid $color-black;
                border-collapse:collapse;

                padding:5px;
                font-size:14px;	
            }
        }
        .table_legende{
            font-size:14px;	
        }
        .box_grey{
            .table_border{
                margin-top:-$space/2;
                
                thead{
                    &:before{
                        content: '';
                        display: block;
                        height: $space;
                        background: none;
                        border:none;
                    }
                }
            }
        }

        .td_noborder{ 
            border-left: none !important; 
            border-right: none !important; 
            border-top: none !important; 
            border-bottom: none !important; 
            &:first-child{
                border-left: 1px solid $color-black !important;
            }
        }
        .td_noborder_ver{
            border-left:none !important;
            border-right:none !important;
        }
        .td_noborder_top {
            border-top:none !important;
        }
        .td_noborder_bottom{
            border-bottom: none !important;
        }
        .td_noborder_hor{ 
            border-top:none !important; 
            border-bottom:none !important; 
        }




        /* LISTS **************************************************************/
        ul, ol {
            padding: 0;
            margin: 0;
            padding-left: 10px;
            max-width: 100%;
            margin-left: 20px;
            position: relative;

            
            li{
                page-break-inside: avoid;
                margin-bottom: 0px;
            }
            &.nodot{
                counter-reset: item;
                list-style-type: none;
                
                li{
                    display: block;
                    &:before{
                        content: counter(item) "  "; 
                        counter-increment: item 
                    }
                }
            }
            &.list_abc {
                list-style-type: none;
                padding-left: $space;
                font-family: $font-light;
                
                li{
                    counter-increment: section;
                    text-indent: 0mm;
                    margin-bottom: 0px;

                    &:before {
                        content: counter(section, lower-alpha) ") ";
                        margin-right: 5px;
                        margin-left: -$space;
                    }
                }
            }
            &.list_abc_grey {
                li{
                    &:before {
                        color: $color-darkgrey;
                    }
                }
            }
            &.list_abc_grey_bold {
                li{
                    &:before {
                        color: $color-darkgrey;
                        font-family: $font-bold
                    }
                }
            }
        }
        
        ul{
            list-style: none;
            
            &.std > li,
            > li{
                &:before{
                    content: '–';
                    position: absolute;
                    margin-left: -20px;
                }
            }
        }
        ol{
            margin-left: $space/2 !important;
        }
        
        
        sup, sub {
            font-size: 10px !important;
        }
        .img_20 {
            width: 20% !important;
            height: auto !important;
        }
        .img_25 {
            width: 25% !important;
            height: auto !important;
        }
        .img_30 {
            width: 30% !important;
            height: auto !important;
        }
        .img_50 {
            width: 50% !important;
            height: auto !important;
        }
        .img_60 {
            width: 60% !important;
            height: auto !important;
        }
        .img_75 {
            width: 75% !important;
            height: auto !important;
        }
        .img_80 {
            width: 80% !important;
            height: auto !important;
        }
        .img_90 {
            width: 90% !important;
            height: auto !important;
        }
        .img_100 {
            width: 100% !important;
            height: auto !important;
        }
        .content {
                width: 100%;
                width: calc(100% - 60px);
                vertical-align: top;
                margin-left: 60px;
                display: block;
                page-break-before: avoid;
                position: relative;
                margin-bottom:15px;
        } 
        .content.dontsplit {
                margin-left: 0;
                width: 100%;
        }
        .content_number {
                position: absolute;
                left:-60px;
                width:60px;
                top:0;
        }
        .chapternumber {
          vertical-align: top;
          display: inline-block;
          width: 50px;
          margin-right: 10px;
        }
        .chaptertitle {
          /*display: inline-block;*/
          width: 65%;
          width: calc(100% - 70px);
        }
        
        
        .abstand_nach {
            position: relative;
            display: block;
            /*display: inline-block;*/
            padding-bottom: $space/2;
            margin-bottom: 0px;
        }
        .abstand_vor {
            position: relative;
            display: block;
            padding-top: $space/2;;
            margin-bottom: 0px;
        }
        .blocktitle {
            font-size: $font-size;
            font-family: $font-standard;
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
        }
        .blocktitle_i {
            font-size: $font-size;
            font-family: $font-bold;
            font-style: italic;
            padding-top: 5px;
            padding-bottom: 5px;
            display: inline-block;
        }
        .box_grey {
            background: $color-midgrey;
            padding: $space;
            margin-bottom: $space;
            box-decoration-break: clone;
            position: relative;
            
            li {
                margin-bottom: 0px;
            }
            ol {
                padding-left: 35px !important;
                margin-left: 0px !important;
            }
            .blocktitle {
                page-break-after: avoid;
            }
        }
        .small_text {
            font-size: 14px;
            font-weight: lighter;
        }
        .big_text {
            font-size: 20px;
            font-weight: lighter;
        }
        .text_abstand {
            margin-left: $space;
            text-indent: -$space;
        }
        .rotate_90{
            max-width: 50px;
            height: 180px;
            padding-bottom:$space !important;
            text-align: inherit;
        }
        .rotate_90 div{
            font-family: $font-light;
            white-space:nowrap;
            transform: rotate(-90deg);
            transform-origin: 26px;
            margin-top: 160px;
            float: left;
        }
        .rotate_90.rot_2 div{
            transform-origin:19px;
            margin-left:10px;
        }
        .rotate_90.rot_3 div{
            transform-origin:13px;
            margin-left:15px;
        }
        .anm_box {
            border-top: 1px solid $color-black;
            border-bottom: 1px solid $color-black;
            font-family: $font-light;
            padding: $space;
            font-style: italic;
            margin-top: $space;
            margin-bottom: $space;
            
            .ninchar {
                font-style: normal;
            }
            .anm_tit {
                font-style: normal;
                font-family: $font-bold;
                margin-bottom: $space/2;
            }
            ul {
                margin-left: $space !important;
                
                li {
                    font-family: $font-light;
                    margin-bottom: 0px;
                    line-height: $line-height;
                }
            }
        }
        .anm_line {
            width: 40%;
            height: 1px;
            background: $color-grey;
            margin-top: $space;
            margin-bottom: $space;
        }
        .normaltext {
            font-family: $font-light;
            line-height: $line-height;
            font-size: $font-size !important;
            margin-left: $space;
        } 

        .normaltext tr td:first-child {
            width: $space;
        }
        .second_table {
          line-height: $line-height;
        }
        .second_table tr td:first-child {
            width: 7.6%;
            padding-right: 5px;
            text-align: right;
        }
        
        /********************** check for use *********************************/
        
        /*
        .header {
          position: fixed;
          top: -1cm;
          left: 0px;
          right: 0px;
        }
        .header .hcontent {
          text-align: right;
          margin-top: -$space;
        }

        .pagebreak {
          page-break-after: always;
        }
        .chapterwrapper {
          orphans: 3;
          widows: 3;
        }
        .chapterwrapper.noshow {
                display: none;
        }
        .row_number {
          display: block;
          font-weight: bold;
          width: 19mm;
          vertical-align: top;
          position: absolute;
          text-align: right;
        }
        .content {
          width: 159mm;
          vertical-align: top;
          margin-left: 14mm;
          display: block;
          page-break-before: avoid;
        } 
        .content.blackborder{
                 width: 159mm;
                border:1mm solid #87bc40;
                margin-left:-1mm;
        }
        .blackborder_left, .blackborder_left_block {
                display: inline-block;
                border-left: 0.3mm solid #000;
                margin-left: -7mm;
                padding-left: 6mm;
        }
        .blackborder_left_spacing {
                display: inline-block;
                border-left: 0.3mm solid #000;
                margin-left: -7mm;
                padding-left: 6mm;
                padding-bottom:1mm;	
        }        
        */
    }
}