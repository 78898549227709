.portlet {
    padding: 12px 20px 15px;
    margin-bottom: 20px;
    background-color: $color-white;
    .portlet-title {
        padding: 0;
        min-height: 50px;
        border-bottom: 1px solid #eee;
        padding: 0;
        margin-bottom: 10px;
        display: flex;
        
        h2 {
            font-family: $font-light;
            font-weight: normal;
            
            color: $color-darkgrey;
            font-size: 25px;
            
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            line-height: 50px;
        }
        .actions{
            flex: 0 0 470px;
            text-align: right;
            
            .action-button{
                cursor: pointer;
                float: right;
                margin-left: 5px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                padding: 7px;
                
                svg{
                    height: 25px;
                    width: 25px;
                }

                border: dashed #999 1px;
                
                &.highlighter,
                &.betoggler{
                    &.selected{
                        background: #e5e5e5;
                        border: solid #e5e5e5 1px;
                    }
                }
                &.betoggler{
                    margin-right: 40px;
                }
            }
            .print-button{
                cursor: pointer;
                float: right;
                margin-right: 20px;
                height: 40px;
                width: 40px;
                padding: 5px;
                
                svg{
                    height: 30px;
                    width: 30px;
                }
            }
            .lang-button{
                font-family: $font-bold;
                border: solid 1px $color-black;
                margin: 8px $space/2 8px 0;
                vertical-align: middle;
                float: right;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
                
                &.selected{
                    background: $color-grey;
                }
                &.last{
                    margin-right: 40px;
                }
                
            }
        }
    }
}
@media(max-width:1023px){
    .portlet {
        .portlet-title {
            .actions{
                flex: 0 0 260px;
                
                .action-button{
                    &.betoggler{
                        clear: right;
                        margin-right: 0;
                    }
                }
                .lang-button{
                    &.last{
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}
