body.product{

    #content{
        overflow: hidden;
        overflow-y: auto;
        height: 100vh;
        height: calc(100vh - 188px);
        
        .loading{
            width: 100%;
            text-align: center;
            padding: 50px;

            img {
                width: 100px;
            }
            span{
                display: block;
                text-align: center;
            }
        }
        .init-content{
            font-size: 18px;
            cursor: pointer;
            display: block;
            margin-bottom: 5px;
            
            display: flex;
            strong{
                flex-grow: 2;
            }
            
            span{
                flex: 0 0 80px;
                margin-right: $space;
                &.wide{
                    flex: 0 0 150px;
                }
            }
            
            i{
                flex: 0 0 40px;
                svg{
                    height: 25px;
                    width: 25px;
                    fill: $color-green;
                    vertical-align: middle;
                    margin-right: $space;
                }
                &.symbol1{
                    svg{
                        fill: $color-darkgreen;
                    }
                }
                &.symbol2{
                    svg{
                        fill: $color-yellow;
                    }
                }
                &.symbol3{
                    svg{
                        fill: $color-green;
                    }
                }
            }
        }
    }
    .col1{
        width: 25%;
        width: calc(25% - #{$space*2});
    }
    .col2{
        overflow: hidden;
        position: relative;
    }
    .col4{
        width: 100%;
    }
}