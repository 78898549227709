// ESTI

// 170mm = 100%
// 1.7mm = 1%

body.product{
    .layout19{
        
        .content-box{
            /*max-width: 1200px;*/
            .pagebreak{
                padding-bottom: $space;
                clear: both;
            }
        }        
        h1{
            display: none;
        }
        .content_number {
            position: static;
            display: inline;
            width: auto;
            left: auto;
            top: auto;
            margin-right: 10px;
        }
        .content-inner{
            &.notitle{
                h2{
                    display: none;
                }
                h3{
                    display: none;
                }
            }
        }
        #titlepage_head {
            margin-bottom:120px;
            with:100%;
        }
        #logo_esti {
                width: 270px !important;
                height: auto !important;
                margin-left: -60px;
        }
        #titlepage_head{
            tr{
                display: flex;
                
                td{
                    &:first-of-type{
                        flex: 270px 1 0;
                    }
                    &.big {
                        font-family: $font-bold;
                        font-size: 14px;
                    }
                }
            }       
        }
        table.box {
            border:1px solid $color-black;	
            width:120px !important;
            float: right;
        }
        table.box td {
            font-family: $font-bold;	
            padding:5px;
        }
        #titlepag_metainfo{
            font-family: $font-bold;
            font-weight: normal;
            margin-bottom: 30px;
            font-size:18px;
        }
        #titlepage_title {
            font-family: $font-bold;
            font-size:30px;

            margin-bottom:$space;
        }
        #titlepage_subtitle{
            font-family: $font-bold;
            font-size:26px;
            margin-bottom:50px;	
        }
        #titlepage_footer {
            margin-top:25px;	
            position:static;
        }
        #titlepage_footer td {
            width:50%;
        }
        .titlepage_info {
            margin-top:25px;	
            margin-bottom: 100px;
            font-family: $font-light;
        }
        .titlepage_info td.first {
            width:130px;	
        }
        /*
        img {
          image-resolution: 300dpi;
        } 
        // global
        p {
          font-size: 9pt;
          margin-bottom: 0.1em;
          margin-top: 0.1em
        }
        td {
          vertical-align: top
        }
        html{
                hyphens: auto;
        }
        body {
          font-size: 9pt;
          line-height: 12pt;
          font-family: $font-light;
        }
        strong, b {
          font-family: $font-bold;
          font-weight: bold;
        }
        h1 {
          font-family: $font-bold;
          font-size: 12pt;
          font-weight: bold;
          padding-bottom: 2mm;
          padding-top: 0.3mm;
          line-height: 16pt;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h2 {


          font-family: $font-bold;
          font-size: 12pt;
          font-weight: bold;
          padding-bottom: 2mm;
          padding-top: 0.3em;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h3 {
          font-family: $font-bold;
          font-size: 11pt;
          font-weight: bold;
          padding-bottom: 2mm;
          padding-top: 0.3em;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h4 {
          font-family: $font-bold;
          font-size: 11pt;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: 2mm;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h5 {
          font-family: $font-bold;
          font-size: 09pt;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: 2mm;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h6 {
          font-family: $font-bold;
          font-size: 09pt;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: 2mm;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        table { 
          position: relative;
          font-weight: normal;
          width: 100% !important;
          border: none;
        }
        table td {
          border: none;
        }
        body > h1 {
          padding-bottom: 0.1em;
        }
        img {

          max-width: 100%;
          height: auto !important;
        }
        ul li {
          page-break-inside: avoid;
        }
        ul > li:before {
          content: '–';
          position: absolute;
          margin-left: -2.5mm;
        }
        ul {
          margin-left: 0px !important;
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        ul, ol {
          padding-left: 2mm;
          margin-bottom: 0.5em;
          max-width: 100%;
          margin-top: 0.1em;
          line-height: 12pt !important;
          margin-left: 4mm !important;
        }
        ul li, ol li {
          margin-bottom: 0px !important;
        }
        ul.list_std > li:before {
          content: '–';
          position: absolute;
          margin-left: -2.5mm;
        }
        ol.nodot { 
            counter-reset: item;
            list-style-type: none;
        }
        ol.nodot li { display: block; }
        ol.nodot li:before { 
            content: counter(item) "  "; 
            counter-increment: item 
        }
        

        .header {
          position: fixed;
          top: -1cm;
          left: 0px;
          right: 0px;
        }
        .header .hcontent {
          text-align: right;
          margin-top: -5mm;
        }
        .ninchar {
          font-family: 'NIN3X';
          display: inline;
          margin-top: 0em;
          margin-right: 0.2em;
        }
        .pagebreak {
          page-break-after: always;
        }
        .chapternumber {
          vertical-align: top;
          display: inline-block;
          width: 11mm;
          margin-right: 1mm;
        }
        .chaptertitle {
          display: inline-block;
          width: 156mm;
          margin-left: 2mm;
        }
        .chapterwrapper {
          orphans: 3;
          widows: 3;
        }
        .chapterwrapper.noshow {
                display: none;
        }
        .row_number {
          display: block;
          font-weight: bold;
          width: 19mm;
          vertical-align: top;
          position: absolute;
          text-align: right;
        }
        // table of contents
        .tocpagenr { float:right; }
        .tocpagenr::after { content: target-counter(attr(href), page); }

        .content_toc {
                width: 159mm;
                margin-left:14mm;
                position: relative;
                margin-bottom:10mm;
                box-decoration-break: clone;

        }
        .tocleft {
                font-family: 'HelveticaNeueLT Pro 65 Md';
                border-bottom:0.3mm dotted #000;
                position: relative;
                height:5mm;
                margin-bottom:3mm;
                page-break-after: avoid;
                display: block;
                page-break-inside:avoid;
        }
        .tocleft_num{
                position:absolute;
                left:0mm;
                top:0mm;
                background: #fff;
                height:4mm;
                padding-top:2.1mm;
                padding-right:1mm;
                width:10mm;
                overflow: hidden;
        }

        .tocleft_name{
                position:absolute;
                left:10mm;
                top:0mm;
                background: #fff;
                min-height:4mm;
                padding-top:2.1mm;
                padding-right:1mm;
                max-width:140mm;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
        }
        .tocpagenr {
                color:#000;	
                text-decoration: none;
                float:right; 
                width:auto;
                padding-left:1mm;
                text-align: right;
                padding-top:2.1mm;
                background: #fff;
        }

        .tocsubleft {
                margin-left:3mm;
                clear:left;
                margin-bottom:2mm;
                position: relative;
                height:5mm;
                border-bottom:0.3mm dotted #000;
                display: block;
                page-break-inside:avoid;
        }
        .tocsubleft_num{
                position:absolute;
                left:0mm;
                top:0mm;
                background: #fff;
                height:4mm;
                padding-top:2.1mm;
                padding-right:1mm;
                width:10mm;
                overflow: hidden;
        }

        .tocsubleft_name{
                position:absolute;
                left:10mm;
                top:0mm;
                background: #fff;
                height:4mm;
                padding-top:2.1mm;
                padding-right:1mm;
                max-width:85mm;
                overflow: hidden;
        }
        .content {
                width: 159mm;
                vertical-align: top;
                margin-left: 14mm;
                display: block;
                page-break-before: avoid;
                position: relative;
                margin-bottom:5mm;
                text-align: justify;
        } 
        .content.footnotepage {
                height:225mm;	
        }
        .content_number {
                position: absolute;
                left:-14mm;
                width:14mm;
                top:0mm;
        }
        .content.titlepage{
                width: 173mm;
                height:280mm;
                margin-left: 0mm;	
        }
        .content.attachment{
                width: 173mm;
                margin-left: 0mm;	
        }
        .blackborder_left, .blackborder_left_block {
                display: inline-block;
                border-left: 0.3mm solid #000;

                margin-left: -7mm;
                padding-left: 6mm;
        }
        .blackborder_left_spacing {
                display: inline-block;
                border-left: 0.3mm solid #000;
                margin-left: -7mm;
                padding-left: 6mm;
                padding-bottom:1mm;	
        }
        sup, sub {
          font-size: 6pt !important;
        }
        .img_25 {
          width: 25% !important;
          height: auto !important;
        }
        .img_30 {
          width: 30% !important;
          height: auto !important;
        }
        .img_50 {
          width: 50% !important;
          height: auto !important;
        }
        .img_60 {
          width: 60% !important;
          height: auto !important;
        }
        .img_75 {
          width: 75% !important;
          height: auto !important;
        }
        .img_80 {
          width: 80% !important;
          height: auto !important;
        }
        .img_90 {
          width: 90% !important;
          height: auto !important;
        }
        .img_100 {
          width: 100% !important;
          height: auto !important;
        }
        .abstand_nach {
          position: relative;
          display: block;
          padding-bottom: 2mm;
          margin-bottom: 0px;
        }
        .abstand_vor {
          position: relative;
          display: block;
          padding-top: 2mm;
          margin-bottom: 0px;
        }
        .blocktitle {
          font-size: 9pt;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: 0.3em;
          display: block;
        }
        .blocktitle_i {
          font-size: 9pt;
          font-weight: bold;
          font-style: italic;
          padding-top: 0.3em;
          padding-bottom: 0.3em;
          display: inline-block;
        }
        .list_abc {
          list-style-type: none;
          padding-left: 5.5mm;
          font-family: $font-light;
        }
        .list_abc li:before {
          content: counter(section, lower-alpha) ") ";
          margin-right: 1mm;
          margin-left: -4.5mm;
        }
        .list_abc > li {
          counter-increment: section;
          text-indent: 0mm;
          margin-bottom: 0px;
        }

        .small_text {
          font-size: 7pt;
          font-weight: lighter;
        }
        .big_text {
          font-size: 16pt;
          font-weight: lighter;
        }
        .text_abstand {
          margin-left: 4mm;
          text-indent: -4.5mm;
        }
        ol {
          margin-left: 1.5mm !important;
        } 
        table ol {
          margin-left: 3mm !important;
        } 
        .rotate_90 {
          max-width: 40px;
          height: 170px;
          padding-bottom: 4mm !important;
          text-align: inherit;
        }
        table.smaller .rotate_90.rot_3{
                max-width:95px;	
                height: 120px;
        }
        table.smaller .rotate_90.rot_3 div{
                width: 45mm !important;
                margin-left: 36px;
                margin-top: 111px;
        }
        .rotate_90 div {
          font-family: $font-light;
          white-space: nowrap;
          transform: rotate(-90deg);
          transform-origin: 26px;
          margin-top: 140px;
          float: left;
        }
        .rotate_90.rot_2 div {
          transform-origin: 19px;
          margin-left: 10px;
        }
        .rotate_90.rot_3 div {
          transform-origin: 13px;
           margin-left: 53px;
        } 
        // tables
        table.table_25 {
          width: 50mm !important;
          font-family: $font-light;
        }
        table.table_50 {
          width: 73mm !important;
          font-family: $font-light;
        }
        table.table_75 {
          width: 110mm !important;
          font-family: $font-light;
        }
        table.table_100 {
          width: 100% !important;
          font-family: $font-light;
                border:none;
        }
        table.table_100 td{
                border:none;	
                padding:0;
        }
        table.dotted, table.dotted td {
                border:0.4mm dotted #000;
                border-collapse: collapse;
        }
        table.dotted td {
                min-height: 5mm;	
                padding:0.5mm;
        }
        .td_50 {
          width: 50px;
          font-family: $font-light;
        }
        .table_border {
          width: 100% !important;
          font-family: $font-light;
          border-bottom: 1px solid #000 !important;
        }
        .table_border.smaller, .table_border.smaller ul, .table_border.smaller ol {
                font-size:7pt !important;
                line-height: 8pt !important;
        }
        .table_border td, .table_border th, .table_100 .table_border td, .table_100 .table_border th {
          border: 1px solid black;
          border-collapse: collapse;
        }
        .table_border {
          border: none;
          border-collapse: collapse;
        }

        .table_border td {
          padding: 1mm;
        }
        .table_legende {
          font-size: 7pt;
          line-height: 11pt;
        }
        .td_noborder {
          border-left: none !important;
          border-right: none !important;
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
        }
        .td_noborder:first-child {
          border-left: 1px solid #000 !important;
          font-family: $font-light;
        }
        .td_noborder_ver {
          border-left: none !important;
          border-right: none !important;
          font-family: $font-light;
        }
        .td_noborder_top, .td_noborder_bottom {
          font-family: $font-light;
        }
        .td_noborder_top  {
           border-top: none !important;
        }
        .td_noborder_bottom {
                border-bottom: none !important;
        }
        .td_noborder_hor {
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
        }
        .td_line_diag {
          background-image: linear-gradient(
            to top right,
            white 49%,
            black,
            white 50%
          );
        }
        .anm_box {
          border-top: 0.8pt solid #898a8d;
          border-bottom: 0.8pt solid #898a8d;
          font-family: $font-light;
          padding: 4mm;
          font-style: italic;
          margin-top: 4mm;
          margin-bottom: 4mm;
        }
        .anm_box .ninchar {
          font-style: normal;
        }
        .anm_box .anm_tit {
          font-style: normal;
          font-family: $font-bold;
          margin-bottom: 2mm;
        }
        .anm_box li {
          font-family: $font-light;
          margin-bottom: 0px;
          line-height: 14pt;
        }
        .anm_box ul {
          margin-left: 4mm !important;
        }
        .anm_line {
          width: 69mm;
          height: 0.5px;
          background: #898a8d;
          margin-top: 4mm;
          margin-bottom: 4mm;
        }
        .normaltext {
          font-family: $font-light;
          line-height: 12pt;
          font-size: 9pt !important;
          margin-left: 4mm;
        } 

        .normaltext tr td:first-child {
          width: 5mm;
        }
        .second_table {
          line-height: 15.5pt;
        }
        .second_table tr td:first-child {
          width: 13mm;
          padding-right: 1mm;
          text-align: right;
        }
        .chapteranchor {
          height: 0px !important;
          display: block !important;
        } 
        .new + .chapterwrapper h1, .new + .chapterwrapper h2, .new + .chapterwrapper h3, 

        .footnote {
                position:absolute;
                padding-top:1mm;
                bottom:0mm;
                left:2mm;
                width:125mm;
                border-top:0.2mm solid #000;
        }

        .footnote ol {
                counter-reset: item;
            margin: 0;
            padding: 0;
            list-style:none;
        }

        .footnote ol {
                list-style: none;
        }
        .footnote ol li {
            display: block;
                list-style: none;
        }
        .dottednum_wrapper {
                border-bottom:0.3mm dotted #000;
                position: relative;
                height:5mm;
                margin-bottom:3mm;
                page-break-after: avoid;
                display: block;
                page-break-inside:avoid;
        }
        .dottednum_left{
                position:absolute;
                left:0mm;
                top:0mm;
                background: #fff;
                height:4mm;
                padding-top:2.1mm;
                padding-right:1mm;
                max-width:150mm;
                overflow: hidden;
        }
        .dottednum_right {
                color:#000;	
                text-decoration: none;
                float:right; 
                width:auto;
                padding-left:1mm;
                text-align: right;
                padding-top:2.1mm;
                background: #fff;
        }

        .footnote ol li:before {
            content: counter(item);
            counter-increment: item;
            display: inline-block;
            text-align: left;
            width: 1em;
            padding-right: 0.5em;
            margin-left: -1.5em;       
            vertical-align:super;
            font-size:5pt;
        }
        .titlepage .row_wrapper, .titlepage .row_number_left {
                display: none;	
        }
        #titlepage_head {
                margin-top:-15mm;	
                margin-bottom:30mm;
                with:100%;
        }
        #titlepage_head td.big {
                text-align:right;
                font-family: $font-bold;
                width:90mm;
        }
        table.box {
                border:0.2mm solid #000;	
                width:60mm !important;
                float: right;
        }
        table.box td {
                font-family: $font-bold;	
                padding:1mm;
        }
        #titlepage_title {
                font-family: $font-bold;
                font-size:18pt;

                line-height: 22pt;
                margin-bottom:5mm;
        }
        #titlepage_subtitle{
                font-family: $font-bold;
                font-size:14pt;
                line-height: 18pt;
                margin-bottom:5mm;	
        }
        #titlepage_footer {
                position:absolute;
                bottom:6mm;
        }
        #titlepage_footer td {
                width:50%;
        }
        .titlepage_info {
                margin-top:5mm;	
                font-family: $font-light;
        }
        .titlepage_info td.first {
                width:30mm;	
        }
        .attachment {
                page:attachment;
        }
        .attachment h1 {
                display:none;
        }
        .attachment_nohead {
                page:attachment_nohead;
                width: 173mm;
                margin-left:0mm;
        }
        .attachment_nohead h2 {
                display: none;	
        }
        .dontsplit.attachment h2 .chapternumber {
                display: none;
        }
        .dontsplit.attachment h2 .chaptertitle {
                margin-left:0;
        }
        */

    }
}