body.product{
    header{
        margin: $space;
        transition: margin-left .2s ease;
        height: auto;
        
        .product-title{
            position: relative;
            margin-bottom: 0;
            color: $color-black;
            font-size: 35px;
            cursor: pointer;
            display: inline-block;
            font-size: 20px;
            margin-right: 30px;
            
            h1{
                font-size: 30px;
            }

            
            .header-icon{
                width: 15px;
                height: 15px;
                margin-left: 10px;
                fill: $color-darkgrey;
            }
        }

        .productswitch{
            display: none;
            position: absolute;
            background: $color-white;
            box-shadow: 2px 1px 13px -1px rgba(0,0,0,.5);
            z-index: 100;
            padding: 10px 0;
            max-height: 80vh;
            overflow: hidden;
            overflow-y: auto;

            li{
                list-style: none;
                a{
                    padding: 5px 20px;
                    display: block;
                    min-width: 300px;
                    max-width: 450px;
                    font-family: $font-bold;
                    font-size: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }       
}