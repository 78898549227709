#search-global{
    width: 0;
    top: 0;
    right: 0;
    height: 100vh;
    background: #dfdfdf;
    transition: width .3s ease;
    position: absolute;
    z-index: 1002;
    box-shadow: -12px 3px 12px -8px rgba(0,0,0,0.5);
    overflow: hidden;

    &.open{
        width: 100%;
    }
    h2 {
        padding: 20px;
        background: #ccc;
        position: relative;
        min-width: 650px;
        white-space: nowrap;
        
        #search-global-close1,#search-global-close2{
            cursor: pointer;
            vertical-align: middle;
            display: inline-block;
            height: 25px;
            
            svg{
                width: 25px;
                height: 25px;
            }
        }
        #search-global-close1{
            margin-right: 20px;
        }
        #search-global-close2{
            float: right;
            svg{
                width: 35px;
                height: 35px;
            }
        }
    }
    #search-global-result{
        margin: 20px;
        width: 90%;
        width: calc(100% - 40px);
        height: 80%;
        height: calc(100% - 120px);
        overflow: auto;
        
        h3{
            margin: 0 0 20px 0;
            color: $color-darkgrey;
            cursor: pointer;
            svg{
                height: 15px;
                width: 15px;
                vertical-align: middle;
                margin-right: 10px;
                fill: $color-darkgrey;
            }
        }
        h4{
            margin: 0 0 20px 0;
            color: $color-green;
            cursor: pointer;
            font-size: 18px;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
            svg{
                height: 15px;
                width: 15px;
                vertical-align: middle;
                margin-right: 10px;
                fill: $color-green;
            }
        }
        .search-result-project{
            margin: 0 0 20px 20px;
            list-style: none;
            display: none;
            
            .search-result-list{
                margin: 0 0 20px 20px;
                list-style: none;
                display: none;

                .searchresult{
                    background: #fff;
                }
            }
        }
    }
}
#back-global-button,
#search-global-button{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 35px;
    z-index: 601;
    cursor: pointer;
    
    svg{
        width: 40px;
        height: 40px;
        fill: #666;
    }
}
body.product{
    #search-global-button{
        top: 20px;
        right: 35px;
    }
    #back-global-button{
        top: 20px;
        right: 85px;
    }
}
body.home{
    #search-global-button{
        top: 10px;
        right: 20px;
        position: fixed;
        svg{
            width: 30px;
            height: 30px;
            fill: #999;
        }
    }
    #back-global-button{
        display: none;
    }
}
@media(max-width:1023px){
    body.home{
        #search-global-button{
            right: 85px;
            width: 70px;
            height: 73px;
            top: 0;
            padding: 20px;
        }
    }
}
