#dv_tooltip{
    display: none;
    position: absolute;
    z-index: 1500;
    
    div{
        padding: 8px 15px;
        background: #666;
        box-shadow: 0 5px 5px #ccc;
        -moz-box-shadow: 0 5px 5px #ccc;
        -webkit-box-shadow: 0 5px 5px #ccc;
        border-radius: 4px;
        color: #fff;
        overflow: hidden;
        
        label {
            width: 120px;
            display: block;
            float: left;
        }
    }
    
    p{
       display: block;
       max-width: 300px;
    }
}