body.home{
    
    @include reset_spaces;
    @include text;
    background: $color-white;
    font-family: $font-light;
    font-size: 18px;
    height: 100%;
    width: 100%;
    overflow: auto;

    main {
        margin: 98px 0 0 0;
        margin-left: 13%;

        min-height: calc(100% - 200px);

        .inner{
            overflow: hidden;
        }

        section{
            max-width: 1200px;
            margin: auto;
            padding: 0 15px;
        }
    }
}


@media(max-width:1679px){
    body.home{
        main, .popup_container{
            margin-left: 17%;
            section{
                padding: 0 30px;
            }
        }
    }
}

@media(max-width:1449px){
    body.home{
        main, .popup_container{
            margin-left: 20%;
        }
    }
}

@media(max-width:1179px){
    body.home{
        main, .popup_container{
            margin-left: 22%;
        }
    }
}

@media(max-width:1023px){
    body.home{
        main{
            margin-left: 0;
            margin-top: 40px;
        }
        .popup_container {
            margin-left: 0;
        }
    }
}