.note{
    margin: 0 0 30px 0;
    border: 0;
    border-left: 5px solid #ccc;
    padding: $space;
    
    &.note-error{
        background: #ff9999;
        border-left: 5px solid #a6000f;
    }
    &.note-success{
        background: lighten($color-green,30%);
        border-left: 5px solid $color-green;
    }
    &.note-warning{
        background: #fffdca;
        border-left: 5px solid #ffc71a;
    }
    &.inline{
        margin-left: 0;
        margin-right: 0;
    }
    &.top{
        margin-top: 0;
    }
}
#fullscreen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 99;
    justify-content: center;
    .popup_container {
        margin-top: 20vh;
        .popup {
            border: solid 3px #88bd24;
            padding: 5px;
            background-clip: content-box; /* support: IE9+ */
            //border-style: double; 
            color: #FFFFFF;
            width: 60%;
            min-width: 200px;
            max-width: 700px;
            padding: 10px;
            margin:auto;
            margin-top: 20px;
            border-radius: 10px;
            .popup_content {
                border: 1px;
                padding: 30px 30px 65px;
                background-color: #88bd24;
                border-radius: 10px;
                h2{
                    margin-bottom: 20px;
                }
                p{
                    margin-bottom: 20px;
                }
                .btn {
                    float:right;
                    margin-left: 10px;
                    background-color: #FFFFFF;
                    color: #88bd24;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media(max-width:1024px){
    .note{
        margin: 0 0 40px 0;
    }
}