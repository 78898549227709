// SNG 491000

body.product{
    .layout13{
        position: relative;
        padding-bottom: 160px;
        
        
        .content-box{
            /*max-width: 1200px;*/
            .pagebreak{
                padding-bottom: $space;
                clear: both;
            }
        }

        h1 {
            display: none;
        }
        h2{ 
            font-size:30px; 
            line-height: 35px; 
            font-family: $font-standard;  
            color: $color-green; 
            padding-bottom:40px; 
            width: 73.529%; 
            font-weight: normal;  
        }
        h3, h4 { 
            font-size:20px; 
            line-height: 24px; 
            font-family: $font-bold;
            font-weight: normal; 
            color: $color-darkgrey; 
            padding-bottom:$space/2; 
            clear: left; 
            float:left;
            display: block; 
            width:73.529%;
            padding:0;
            margin:0 0 5px 0;
            page-break-after: avoid;
        } 
        
        .title_grey {
            font-family: $font-bold;
            color: $color-darkgrey;
            font-weight: normal;
        }

        table { 
            border-spacing: 5px; 
            text-align: left; 
            vertical-align: top; 
            border-collapse: separate; 
            
            th, td {
                border-left:none;
                border-right:none;
                border-top:none;
                border-bottom:1px solid $color-grey; 
                font-weight: normal;
                padding-left: 5px;
                padding-right:10px;
                
                &.tdgreyborder{
                    border-bottom:3px solid $color-grey !important;
                }
                &.tdgreenborder{
                    border-bottom:3px solid $color-green !important;
                }
            }
            &.table_black {
                border:1px solid $color-grey;
                border-collapse: collapse;
                
                td{
                    border: solid 1px $color-grey;
                    padding:0;
                    margin:0;
                    width: 25px;
                    height: 25px;
                }
            }
            &.noborder{
                border-spacing: 0; 
                border-collapse: collapse; 
                border:none;
                
                th, td{
                    border:none;
                    padding:0;
                    margin:0;
                }
            }
        }
        ul, ol{
            margin-bottom:$space/2;
            margin-top:$space;
        }
        td ul {
            padding-top:0;
            padding-bottom:0;
            margin-bottom: 0;
            margin-top: 0;
        }
        ul ul{ 
            padding-top:0;
            padding-bottom:0;
        }
        .img_50.spacing {
                width: 45%;
        }
        .img_50.left{
                padding-right:2.5% !important;
                width: 47.5% !important;

        }
        .img_50.right{
                padding-right:2.5% !important;
                width: 47.5% !important;
        }
        
        .wrapper {
                position: relative;
                width: 100%;
                clear: left;
        }
        .maincon {
                width:73.529%;
                padding:0;
                margin:0;
                margin-bottom:$space;
                /*text-align: justify;*/
        }
        .maincon.greenborder{
                width:73.529%;
                width:calc(73.529% - 2px);
                border:5px solid $color-green;
                padding:10px;
                margin-left:-5px;
        }
        .rightcon {
                position:absolute;
                left:75.581%;
                top:0;
                width:17.441%;
                font-size:14px;
                color: $color-darkgrey; 
        }
        /*
        .rightcon a {
                font-size:14px;
                color: $color-darkgrey; 	
        }*/
        .fullcon {
                width:92.352%;
                margin-bottom:$space;
                text-align: justify;
                clear: left;
        }
        .footnote {
                /*position:absolute;*/
                padding-top:5px;
                bottom:70px;
                left:0;
                width:73.529%;
                border-top:1px solid #000;
        }

        .con50_left {
                width:47%;
                margin-right:2.5%;
                float:left;
        }
        .con50_right{
                width:47%;
                margin-left:2.5%;
                float:left;
        }
        .con50_left.blue{
                background:#bdd3e7;
                border:2px solid $color-blue;
                padding:5px;
        }
        .con50_right.green{
                background:#dbeaba;
                border:2px solid $color-green;
                padding:5px;
        }
        .box_green {
                border-top:5px solid $color-green;
                border-bottom:5px solid $color-green;
                padding-left:80px;
                padding-right:80px;
                padding-top:10px;
                padding-bottom:10px;
                color: $color-darkgrey;
        }
        .title_green {
                font-family: $font-bold;
                font-size:20px;
                padding-bottom:10px;	
                color: $color-green;
        }
        .small_grey {
                color: $color-darkgrey;
                font-size:14px;
        }
        .small {
                font-size:14px;
        }
        .spacing_aft {
                padding-bottom:5px;	
                display: block;
        }
        .spacing_aft_inline {
                padding-bottom:5px;	
                display: inline-block;
        }
        .greenborder_left, .greenborder_left_block {
                display: inline-block;
                border-left: 5px solid $color-green;
                margin-left: -$space;
                padding-left: 2.325%;
        }
        .greenborder_left_spacing {
                display: inline-block;
                border-left: 5px solid $color-green;
                margin-left: -$space;
                padding-left: 2.325%;
                padding-bottom:5px;	
        }
        .greenborder_left_spacing .img_100 {
                width: 73.529% !important;
                height: auto !important;
        }
        td.greenborder_left {
                display: table-cell;
                border-left:none;
                margin-left:0;
                padding-left:0;
        }
        td.greenborder_left:before {
                content: "";
                border-left: 5px solid $color-green;
                margin-left: -$space;
                left: 0;
                display: block;
                height: 25px;
                position: absolute;
        }
        .chapterwrapper.greenborder_left{
                display: block;
        }
        li .greenborder_left, li .greenborder_left_spacing {
                margin-left:-9mm;
                padding-left: 8mm;
        }
        .change h1, .change h2 {
                display: inline-block;
                border-left: 5px solid $color-green;
                margin-left: -$space;
                padding-left: 2.325%;
        }
        /* tables */
        .maincon table.table_25{ width: 18.023% !important; font-family: $font-light; font-size:14px; }
        .maincon table.table_50{	width: 36.046% !important; font-family: $font-light; font-size:14px;}
        .maincon table.table_75{	width:54.069% !important; font-family: $font-light; font-size:14px;}
        .maincon table.table_100{	width:73.529% !important; font-family: $font-light; font-size:14px;}
        .fullcon table.table_25{ width: 22.674% !important; font-family: $font-light; font-size:14px; }
        .fullcon table.table_50{	width: 45.348% !important; font-family: $font-light; font-size:14px;}
        .fullcon table.table_75{	width:68.023% !important; font-family: $font-light; font-size:14px;}
        .fullcon table.table_100{	width:90.697% !important; font-family: $font-light; font-size:14px;}
        table.alternate_full{
                width:100%; 
                font-family: $font-light; 
                font-size:16px;
                border-collapse: collapse;
                border:1px solid #e3efd2;
        }
        table.alternate_full th{
                background:#e3efd2;
                padding-left:5px;
                padding-right:5px;
                padding-top:5px;
                text-align: center;
                border-right: 1px solid #fff;
        }
        table.alternate_full td,  table.alternate_full th{
                text-align: left;	
        }
        table.alternate_full tr:nth-child(even) td {
                background:#e3efd2;
                border-right: 1px solid #fff;
        }
        table.green_grey { width:100%; border-spacing:inherit; border-collapse: collapse;}
        table.rotate {
            /*
            transform: rotate(-90deg) translateX(-100%);
            font-family: $font-light; 
            font-size:14px;
            transform-origin: 0 0;*/
        }
        table.green_grey th {
                background:$color-green;
                border-right:1px solid #fff;
                border-bottom:1px solid #fff;
        }
        table.green_grey td {
                border:none;	
        }
        .td_grey {
                background:#dcdcdb;	
                border-bottom:1px solid #dcdcdb !important;
                border-right:1px solid #dcdcdb !important;
        }
        .td_green {
                background:$color-green;	
                border-right:1px solid #fff !important;
        }
        .td_softgreen {
                background:#f2f7e7 !important;
                border:none !important;
        }
        .td_softgreen {
                background:$color-green;	
                border-right:1px solid #fff !important;
        }
        .td_grey_border_dashed {
                background:#dcdcdb;	
                border-bottom:1px dashed #fff !important;
        }
        .td_grey_border_solid_greenlr {
                background:#dcdcdb;	
                border-bottom:1px solid #fff !important;
                border-left:1px solid $color-green !important;
                border-right:1px solid $color-green !important;
        }
        .td_grey_border_dashed_greenlr {
                background:#dcdcdb;	

                border-bottom:0.5mm dashed #fff !important;
                border-left:1px solid $color-green !important;
                border-right:1px solid $color-green !important;
        }
        .td_greenlr {
                border-left:1px solid $color-green !important;
                border-right:1px solid $color-green !important;
        }
        .td_grey_border_solid {
                background:#dcdcdb;	
                border-bottom:1px solid #fff !important;
        }
        .green_grey_title {
                font-size:20px;	
                font-family: $font-bold;
        }
        .green_grey td ul {
                margin-left: 10px !important;
                margin-top:0;
                margin-bottom:0;
                padding-top:0;
                padding-bottom:0;
        }
        td.helper {
                border:none;
                padding:0;
                margin:0;
        }
        .num_circle {
                width: 1.744%;
                height: 10px;
                display: inline-block;
                border: 1px solid #000;
                border-radius: 200px;
                text-align: center;
                padding-bottom: 5px;
                font-size: 6pt;
                line-height: 18px;
        }
        .footnote ol {
            counter-reset: item;
            margin: 0;
            padding: 0;
            list-style:none;
        }

        .footnote ol {
                list-style: none;
        }
        .footnote ol li {
            display: block;
            list-style: none;
            margin-left: 20px;
        }

        .footnote ol li:before {
            content: counter(item);
            counter-increment: item;
            display: inline-block;
            text-align: left;
            width: 20px;
            padding-right: 0.5em;
            margin-left: -26px;       
            vertical-align:super;
            font-size:12px;
        }
        
        /***** DIRTY ********/
        #tqi1639735027411{
            width: 18px;                    
        }
    }
}

/*

        h2 .chapternumber, h3 .chapternumber, h4 .chapternumber{ 
            display: block; 
            float:left; 
            padding-right: $space/2; 
            width:80px; 
            vertical-align: top; 
        }
        h2 .chaptertitle, h3 .chaptertitle, h4 .chaptertitle { 
            width: 65%; 
            width: calc(100% - 80px);
            vertical-align: top; 
            display: block; 
            float:left; 
        }

        .chapterwrapper.nochapternumber h2 .chapternumber, .chapterwrapper.nochapternumber h3 .chapternumber, .chapterwrapper.nochapternumber h4 .chapternumber{ 
            display: inline-block; 
            float:left; 
            padding-right: $space/2; 
            vertical-align: top; 
            width: auto; 
        }
        .chapterwrapper.nochapternumber h2 .chaptertitle, .chapterwrapper.nochapternumber h3 .chaptertitle, .chapterwrapper.nochapternumber h4 .chaptertitle{ 
            width: 65%; 
            vertical-align: top; 
            display: inline-block; 
            float:left;
        }
        .chapterwrapper {
                orphans: 3;
                widows: 3;
        }
        .pagebreak { page-break-after: always; }
        
        ul{
                margin-left:0px !important;
                margin: 0;
                padding: 0;
                list-style-type: none;
        }
        ul, ol{
                padding-left:0;	
                margin-bottom:0.5em;
                max-width:100%;
                margin-top:0.1em;
                line-height:22px !important;
                margin-left:4mm !important;
        }
        ul li, ol li{
                margin-bottom:0 !important;	
        }
        ul{
                padding-top:10px;
                padding-bottom:10px;
        }
        td ul {
                padding-top:0;
                padding-bottom:0;
                margin-bottom: 0;
        }
        ul ul{ 
                padding-top:0;
                padding-bottom:0;
        }
        .list_abc, .list_abc ol {list-style-type: none; padding-left:0;}
        .list_abc li:before {content: counter(section, lower-alpha) ") "; margin-right:5px; margin-left:-4.5mm;}
        .list_abc li { counter-increment: section; text-indent:0; margin-bottom:0px; }

        .list_abc_grey, .list_abc_grey ol {list-style-type: none; padding-left:0;}
        .list_abc_grey li:before {content: counter(section, lower-alpha) ") "; margin-right:5px; margin-left:-4.5mm; color:$color-darkgrey;}
        .list_abc_grey li { counter-increment: section; text-indent:0; margin-bottom:0px; }

        .list_abc_grey_bold, .list_abc_grey ol {list-style-type: none; padding-left:0;}
        .list_abc_grey_bold li:before {content: counter(section, lower-alpha) ") "; margin-right:5px; margin-left:-4.5mm; color:$color-darkgrey; font-family: $font-bold;}
        .list_abc_grey_bold li { counter-increment: section; text-indent:0; margin-bottom:0px; }

        .ninchar { font-family:'NIN3X'; display:inline; margin-top:0em; margin-right:0.2em; }
        .title_grey .ninchar, .small_grey .ninchar { color: $color-darkgrey; }
        .pagebreak { page-break-after: always; }
        sup{
                font-size:5pt;
        }


        // table of contents
        .content_toc {
                display:block;
                width:150;
                margin-left:23mm;
                page-break-inside: inherit;
        }
        .tocline{
                page-break-inside: avoid;
        }

        .content_toc .chapternumber {
           width:21mm; display:block; position: absolute;
           border-bottom:none;
        }
        .content_toc .chaptertitle { 
                width:75.581%;
                margin-left:20;
                display:block;
        }

        // keywords 
        .content_tokw {
                display:block;
                width:100%;
        }
        .wrapkw {
                postion:absolute;
                width:100%;
                margin-left:5px;
                margin-bottom:5mm;
        }
        .wrapkw .keyword {
            display:inline-block;
            vertical-align:top;
            width:60;
            font-weight: bold;
        }

        .content_tokw .kwchapternumber {
                width:26mm; display:inline-block;
        }
        .content_tokw .kwchaptertitle  { 
                width:80; display:inline-block; position: absolute; 
                text-overflow: ellipsis; overflow:hidden; white-space:nowrap;
        }

*/