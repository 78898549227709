// SNR / SNG 2022

// 170mm = 100%
// 1.7mm = 1%

// 4mm = 20px
// 3mm = 15px
// 2mm = 10px
// 1mm = 5px

//  7pt = 12px
//  9pt = 16px
// 10pt = 18px
// 11pt = 20px
// 12pt = 22px
// 24px = 24px
// 14pt = 26px
// 18pt = 32px
// 32pt = 46px
// 40pt = 50px

body.product{
    .layout24{
        
        .content-box{ 
            /*max-width: 1200px;*/
            .pagebreak{
                padding-bottom: $space;
                clear: both;
            }
        }
        
        
        h1{
            display: none;
        }
        h2{
            font-size: 22px;
        }
        h3{
            font-size: 20px;
        } 
        h4{
            font-size: 20px;
        }
        h5, h6, h7{
            font-size: 16px;
        }
        
        
        #logo_sn {
                width:280px !important;
                height: auto !important;
        }
        #logo_es {
                width: 160px !important;
                height: auto !important;
                margin-top: -30px
        }
        .content-inner{
            &.notitle{
                h2{
                    display: none;
                }
                h3{
                    display: none;
                }
            }
        }

        .titlepage .row_wrapper, .titlepage .row_number_left, .coversheet .row_wrapper, .coversheet .row_number_left, .last-page .row_wrapper, .last-page .row_number_left  {
                display: none;	
        }
        .titlepage{
            .abstand_nach{
                display: block;
            }
        }
        #titlepage_head {
                margin-top:30px;	
                margin-bottom:120px;
                font-size: 20px;
        }
        #titlepage_firstpagecon {
            height:155px;	
        }
        #titlepage_head td.line {
                height:1px;
                border-top:1px solid $color-black;
                padding-top: 10px;
        }
        #titlepage_head td .big {
                font-size:32px;	
                text-align:right;
                font-family: $font-bold;
                float: right;
        }
        #titlepage_head td:last-child {
                width:280px;
        }
        .firstpage_big {
                font-size:32px;
                font-family: $font-bold;
                display: block;
        }
        .firstpage_std {
                display: block;
                margin-top:10px;
                font-size:22px;
                line-height: 26px;
        }
        #titlepage_footer {
                position:static;
                bottom:auto;
                margin-top: 300px;
                margin-bottom: 50px;
        }
        #titlepage_footer td {
                padding:2mm;
                margin:0mm;
                border-collapse: collapse;
        }
        #titlepage_footer td.bt {
                border-top:0.2mm solid #000;
        }
        #titlepage_footer td.blr {
                border-left:0.2mm solid #000;
                border-right:0.2mm solid #000;
        }
        #titlepage_footer .td_header{
                font-size: 12px;
                width: 100%;
                display: inline-block;
        }
        #titlepage_secondpagecon {
                page:noheader;
        }
        #totpages {
                content: counter(pages);
        }
        .coversheet{
            h2 { 
                display: none;
            } 
        }
        .notitle{
            h3 { 
                display: none;
            } 
        }
        .coversheet img {
                width:100%;
                height: auto !important;
        }
        .title_front {
                font-size:46px; 
                line-height: 50px; 
                font-family: 'UniversLTPro-55Roman';  
                color: #a0c64c; 
                margin-left:12mm;
                margin-top:31mm;
                width:155mm;
                margin-bottom:14mm;
        }
        .subtitle_front {
                color: #706f6f;
                font-size:13px;
                line-height: 32px;
                margin-left:12mm;
        }
        .last-page {
                page: lastpage;	
        }
        .last-page h2 {
                display: none;	
        }
        .abbinder {
                position: static;
                margin-top: 300px;
        }
        
        table.rotate td { 
                border:0.2px solid #000;
        }

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        /*
        
        
        
        
        
        
        
        
        
        
        
        
        
        .greenborder{
                width:157px;
                border:1px solid #87bc40;
                padding:2px;
                margin-left:-1px;
                display: block;
        }
        // table of contents
        
        .content_toc {
                page-break-inside: inherit;
                color: #706f6f;
                width:159px;
        }
        .tocwrapper, .tocsubwrapper{
                border-bottom:0.2px solid #706f6f;
        }
        .tocwrapper {
                font-family: $font-bold; 
                margin-bottom:3px;
                margin-top:4.5px;
        }
        .tocwrapper:last-child{
                display: none;	
        }
        .tocsubwrapper{
                margin-bottom:1px;
        }
        .tocwrapper .tocl{
                display:inline-block;
                padding-right:1px;
        }
        .tocsubwrapper .tocl {
                width:11px;
                display:inline-block;
                vertical-align: top;
        }

        .tocr{
                display:inline-block;
        }
        .tocsubwrapper .tocr {
                width:112px;
        }

        .tocpagenr {
                width:11px; 
                display:inline-block; 
                float:right;
                text-align: right;
                padding-right:3px;
                color: #706f6f;
                text-decoration: none;
        }
        .tocpagenr::after { content: target-counter(attr(href), page); }

        sup, sub {
          font-size: 6pt !important;
        }
        td sup, td sub {
          font-size: 5pt !important;
        }
        .num_circle {
          width: 3px;
          height: 2px;
          display: inline-block;
          border: 0.2px solid #000;
          border-radius: 200px;
          text-align: center;
          padding-bottom: 1px;
          font-size: 6pt;
          line-height: 18px;
        }
        .num_quadr {
          width: 3px;
          height: 2px;
          display: inline-block;
          border: 0.2px solid #000;
          text-align: center;
          padding-bottom: 1px;
          font-size: 6pt;
          line-height: 18px;
        } 
        // content specific 
        .img_20 {
          width: 20% !important;
          height: auto !important;
        }
        
        // tables 
        table.table_25 {
          width: 50px !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        table.table_50 {
          width: 73px !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        table.table_75 {
          width: 110px !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        table.table_100 {
          width: 159px !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        .td_50 {
          width: 50px;
          font-family: $font-light;
          font-size: 8pt;
        }
        .table_border {
          width: 100% !important;
          font-family: $font-light;
          border-bottom: 1px solid #000 !important;
        }
        .table_border td, .table_border th {
          border: 1px solid black;
          border-collapse: collapse;
        }
        table th {
                boder:none;	
        }
        .table_border {
          border: none;
          border-collapse: collapse;
        }
        .box_grey .table_border {
          margin-top: -4px;
        }
        .box_grey .table_border thead:before {
          content: '';
          display: block;
          height: 4px;
          background: none;
          border: none;
        }
        .table_border td {
          padding: 1px;
          font-size: 12px;
          line-height: 20px;
        }
        .table_legende {
          font-size: 12px;
          line-height: 20px;
        }
        .td_noborder {
          border-left: none !important;
          border-right: none !important;
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        .td_noborder:first-child {
          border-left: 1px solid #000 !important;
          font-family: $font-light;
          font-size: 8pt;

        }
        .td_noborder_ver {
          border-left: none !important;
          border-right: none !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        .td_noborder_top, .td_noborder_bottom {
          font-family: $font-light;
          font-size: 8pt;
        }
        .td_noborder_hor {
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
          font-size: 8pt;
        }
        .td_line_diag {
          background-image: linear-gradient(
            to top right,
            #d0e09a 49%,
            black,
            #d0e09a 50%
          );
        }
        table.alternate_full{
                width:100%; 
                font-family: $font-light; 
                font-size:16px;
                border-collapse: collapse;
                border:0.2px solid #000;
        }
        table.alternate_full th{
                background:#dcdcdb;
                padding-left:1px;
                padding-right:1px;
                padding-top:1px;
                text-align: center;
        }
        table.alternate_full td,  table.alternate_full th{
                text-align: left;
                border:0.2px solid #000;
                border-collapse: collapse;
        }
        table.alternate_full tr:nth-child(even) td {
                background:#dcdcdb;
                border-right: 0.2px solid #fff;
        }
        .td_grey {
                background:#dcdcdb;	

        }
        .chapteranchor {
          height: 0px !important;
          display: block !important;
        }
        .new + .chapterwrapper h1, .new + .chapterwrapper h2, .new + .chapterwrapper h3, .new + .chapterwrapper h4, .new + .chapterwrapper h5, .new + .chapterwrapper h6 {
          margin-top: 8px;
        }
        .new h1:before, .new h2:before, .new h3:before, .new h4:before, .new h5:before, .new h6:before {
          content: '';
          display: inline-block;
          background: url('/files/30/NIN_2020/icon_neu.svg') no-repeat left top;
          background-size: 5px auto;
          height: 8px;
          width: 6px;
          position: absolute;
          margin-top: 6px;
          margin-left: -0px;
        }
        .new .row_number:before {
          content: '';
          display: inline-block;
          background: url('/files/30/NIN_2020/icon_neu.svg') no-repeat left top;
          background-size: 3px auto;
          height: 6px;
          width: 7px;
          position: absolute;
          margin-top: -0.5px;
          margin-left: 10px;
        }
        .mltitle {
          margin-top: 2px;
          font-family: $font-bold;
          font-weight: bold;
        }
        .txtv {
                max-width: 4px;
                height: 170px;
                padding-bottom:4px !important;
                text-align: inherit;
        }
        .txtv div{
                white-space:nowrap;
                transform: rotate(-90deg);
                transform-origin: 2px;
                margin-top: 140px;
                float: left;
        }
        .footnote ol {
                counter-reset: item;
            margin: 0;
            padding: 0;
            list-style:none;
        }

        .footnote ol {
                list-style: none;
        }
        .footnote ol li {
            display: block;
                list-style: none;
        }

        .footnote ol li:before {
            content: counter(item);
            counter-increment: item;
            display: inline-block;
            text-align: left;
            width: 1em;
            padding-right: 0.5em;
            margin-left: -1.5em;       
            vertical-align:super;
            font-size:5pt;
        }
        .nohead {
                page:noheader;	
        }
        */
    }
}