body.home{
    .title{
        margin-bottom: 50px;
        
        h1{
            font-family: $font-standard;
            font-weight: normal;
            margin-bottom: $space;
            color: $color-green;
            font-size: 44px;
            max-width: 70%;
            min-width: 730px;
        }
        p{
            /*max-width: 70%;*/
            min-width: 800px;
            font-size: 20px;
            color: $color-darkgrey;
        }
    }
    .tiles{
        width: 100%;

        .tile{
            display: block;
            width: 31.333%;
            margin: 0 2% 60px 0;
            float: left;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            line-height: 1.5;
            box-shadow: 12px 0 19px 0 rgba(0,0,0,.2);
            height: 550px;
            
            /*
            &:nth-child(3n + 3){
                margin-right: 0;
            }*/

            .tile-link{
                position: absolute;
                width: 100%;
                height: 100%;
                height: calc(100% + 20px);
                z-index: 10;
            }

            .tile-img{
                width: 100%;
                height: 220px;
                background: $color-grey;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .tile-cont{
                padding: 10px 20px 20px 20px;
                overflow: hidden;
                height: 300px;

                h3{
                    background: #fff;
                    color: #666;
                    font-size: 25px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
            }
            .button{

                position: absolute;
                bottom: -17px;
                right: 30px;
                z-index: 5;
                margin-top: 20px;
                width: 75px;
                letter-spacing: .1px;
                text-align: center;
                height: 47px;
                background: $color-green;
                cursor: pointer;

                &:before{
                    content: "";
                    position: absolute;
                    background-image: url('/dist/iconset.svg');
                    background-repeat: no-repeat;
                    background-size: 460px;
                    background-position: -312px 0;
                    width: 30px;
                    height: 20px;
                    top: 11px;
                    left: 23px;
                }
            }
            &.size3{
                width: 98%;
                .tile-img{
                    height: 100%;
                }
                .tile-cont {
                    overflow: visible;
                    height: auto;
                    max-width: 50%;
                    position: absolute;
                    background: #fff;
                    bottom: 50px;
                    left: 50px;
                }
            }
        }       
        &.large{
            .tile{
                display: block;
                width: 32.7%;
                margin: 0.3%;
                height: 300px;
            }
            
        }
    }
    .journal-title{
        margin-bottom: $space;  
    }
    .journal-content{
        .journalheader{
            width: 100%;
            background: $color-green;
            margin-bottom: $space*2;
            margin-top: $space*2;
            
            &:first-child{
                 margin-top: 0;
            }
            
            td,th{
                padding: 10px;
                color: $color-white;
                font-family: $font-bold;
            }
        }
        .journal{
            tr{
                td{
                    padding-right: 10px;
                    padding-bottom: 10px;
                    border-bottom: solid 1px $color-darkgrey;

                    

                    &.tocdiv_txt{
                        font-family: $font-bold;
                        color: $color-darkgrey;
                    }
                }
                &.noborderbtm{
                    td {
                        border-bottom: none;
                        padding-bottom: 5px;
                    }
                }
            }
        }
        ul{
            list-style: none;
            padding-left: $space;
            
            &.std > li,
            > li{
                &:before{
                    content: '–';
                    position: absolute;
                    margin-left: -20px;
                }
            }
        }
        a{
            color: $color-blue;
            text-decoration: none;
        }
        .img_20 {
            width: 20% !important;
            height: auto !important;
        }
        .img_25 {
            width: 25% !important;
            height: auto !important;
        }
        .img_30 {
            width: 30% !important;
            height: auto !important;
        }
        .img_50 {
            width: 50% !important;
            height: auto !important;
        }
        .img_60 {
            width: 60% !important;
            height: auto !important;
        }
        .img_75 {
            width: 75% !important;
            height: auto !important;
        }
        .img_80 {
            width: 80% !important;
            height: auto !important;
        }
        .img_90 {
            width: 90% !important;
            height: auto !important;
        }
        .img_100 {
            width: 100% !important;
            height: auto !important;
        }
        .abstand_nach{
            margin-bottom: $space;
        }
    }
}
@media(max-width:1200px){
    body.home{
        .tiles{
            .tile{
                width: 47%;
                margin: 0 3% 60px 0;
                
                &.size3{
                    width: 97%;
                }
            }
        }
    }
}
@media(max-width:1023px){
    body.home{
        .title{
            p{
                width: 100%;
                max-width: 100%;
                min-width: auto;
            }
        }
    }
}
@media(max-width:550px){
    body.home{
        .tiles{
            .tile{
                width: 100%;
                margin: 0 0 60px 0;
            }
        }
    }
}
