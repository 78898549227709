body.home.errors{
    main {
        margin-top: 0;
        height: 100%;
        
        .inner{
            overflow: visible;
            height: 100%;
        }
    
        .error-container{
            position: relative;
            height: 100%;
            
            .error-tile{
                background: $color-white;
                position: absolute;
                z-index: 10;
                transform: translate(-50%,-50%);
                max-width: 650px;
                top: 50%;
                left: 50%;
                padding: $space;
                
                h1{
                    font-family: $font-standard;
                    font-weight: normal;
                    margin-bottom: $space;
                    color: $color-green;
                    font-size: 44px;
                }
                p{
                    &.lead{
                        font-size: 24px;
                        margin-bottom: 20px;
                    }
                    font-size: 20px;
                    color: $color-darkgrey;

                }
                .actions{
                    margin-top: $space;
                    a {
                        padding: $space/2 $space;
                        background: $color-green;
                        display: inline-block;
                        margin-right: $space;
                        color: $color-white;
                        &:hover{
                            background: lighten($color-green, 10%);
                        }
                    }
                }
            }

            video{
                min-width: 100%;
                min-height: 100%;
                position: absolute;
                z-index: 1;
            }
        }
    }
}