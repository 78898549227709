body{
    &.dock-open{
        .dock{
            .handle{
                display: block;
            }
        }
        .col2{
            flex-grow: 1;
        }
    }
    &.dock-closed{
        .dock{
            .handle{
                display: none;
            }
        }
        .col2{
            flex-grow: 1;
        }
    }
    &.dock-over{
        .dock{
            position: absolute;
            height: 100vh;
        }
        &.product{
            main,header{
                margin-left: 80px;
            }
        }
    }
}
aside.dock{
    flex: 0 0 60px;
    background: #fff;
    box-shadow: 12px 3px 12px -8px rgba(0,0,0,0.5);
    transition: width .2s ease;
    position: relative;
    font-size: 16px;
    z-index: 999;
    
    .handle{
        height: 100%;
        width: 10px;
        background: transparent;
        position: absolute;
        right: -5px;
        cursor: col-resize;
        z-index: 5;
        background: transparent;
        
        &:after{
            content: '. . .';
            position: absolute;
            top: 50%;
            right: 5px;
                
            
            height: 45px;
            width: 10px;
            line-height: 6px;
            font-size: 40px;
            color: #fff;
        }
    }

    
    .inner{
        margin: $space;
        margin-right: 60px;
        height: calc(100% - #{$space * 2});
        display: none;
        
        h2{
            text-transform: uppercase;
            font-weight: 400;
            font-size: 22px;
            margin-bottom: 10px;
        }
        .docklead{
            margin-bottom: $space; 
        }
        .content{
            display: none;
            height: 100%;
            
            .cont {
                height: 100%;
                height: calc(100% - #{$space * 3});
                overflow: auto;
                padding-right: $space/2;

            }
        }

    }
    
    ul.bar{
        height: 100%;
        width: 60px;
        background: $color-green;
        float: right;
        
        li{
            list-style: none;
            overflow: hidden;
            position: relative;
            
            &.selected{
                background: lighten($color-green, 20%);
            }
            
            a {
                width: 40px;
                height: 40px;
                display: block;
                margin: 10px;
                cursor: pointer;
                
                .dock-icon{
                    width: 40px;
                    height: 40px;
                    fill: #fff;
                }
            }
            &.dock-logo{
                width: 60px;
                height: 60px;
                background: #fff;
                
                a{
                    margin: 0;
                    width: auto;
                    height: auto;
                }
                img{
                    width: 50px;
                    margin: 12px 5px;
                }
            }
        }
    }
    .dock-structure{
        a, a:not([href]), a:visited, a:link {
            color: $font-color;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0 0 10px 0;
            
            ul {
                display: none;
                margin-left: 15px;
            }
            
            li{
                display: block;
                
                a, a:not([href]), a:visited, a:link {
                    color: $font-color;
                    display: flex;
                    font-size: 16px;
                    cursor: pointer;
                    i{
                        flex: 0 0 20px;
                        .arrow{
                            width: 12px;
                            height: 12px;
                        }
                    }
                    span {
                        margin-right: 20px;
                        &.ninchar{
                            margin-right: 0;
                        }
                    }
                    strong {

                    }
                }
                li{
                    strong{
                        font-weight: 400;                
                    }
                }
            }
        }
    }
    .dock-search{
        .search-top{
            margin-bottom: $space;
            
            .search-field{
                width: 90%;
                width: calc(100% - #{$space});
                transition: width 0.3s;
            }
            .search-nav{
                width: 0px;
                display: inline-block;
                vertical-align: middle;
                transition: width 0.3s;
                overflow: hidden;
                white-space: nowrap;
                
                a{
                    cursor: pointer;
                }

                svg{
                    width: 25px;
                    height: 25px;
                    fill: $color-darkgrey;
                }
                .search-clear{
                    vertical-align: middle;
                    margin: 0 50px 0 10px;
                    svg{
                        width: 20px;
                        height: 20px;
                        fill: $color-darkgrey;
                    }
                }
            }
            &.active{
                .search-field{
                    width: 80%;
                    width: calc(100% - 145px);
                }
                .search-nav{
                    width: 140px;
                }
            }

        }
        .cont{
            .search-result-project{
                h3{
                    margin: 0 0 5px 0;
                    color: $color-green;
                    font-size: 16px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space:nowrap;
                    font-family: $font-bold;
                    font-weight: normal;

                    svg{
                        height: 15px;
                        width: 15px;
                        vertical-align: middle;
                        margin-right: 10px;
                        fill: $color-green;
                    }
                }
                .search-result-list{
                    margin: 0 0 20px 25px;
                    list-style: none;
                    cursor: pointer;

                    .searchresult{
                        background: $color-brown;
                        &.selected{
                            background: $color-darkbrown;
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
    .dock-keywords{
        a, a:not([href]), a:visited, a:link {
            color: $font-color;
        }
        ul{
            list-style: none;
            
            li{
                display: block;
                a, a:not([href]), a:visited, a:link {
                    color: $font-color;
                    display: flex;
                    font-size: 16px;
                    cursor: pointer;
                    font-weight: 700;
                    i{
                        flex: 0 0 20px;
                        .arrow{
                            width: 12px;
                            height: 12px;
                        }
                    }
                    span {
                        margin-right: 20px;
                    }
                    strong {

                    }
                }
                ul {
                    margin-left: 28px;
                    display: none;
                    
                    li{
                        a, a:not([href]), a:visited, a:link{
                            display: flex;
                            span {
                                font-weight: 400;
                                margin-right: 0;
                                flex: 0 0 70px;
                            }
                            font-size: 16px;
                            strong {
                                font-weight: 400;
                                
                            }
                        }
                    }
                }
            }
        }
    }
    .dock-notations{
        a, a:not([href]), a:visited, a:link {
            color: $font-color;
        }
        ul{
            list-style: none;
            ul{
                margin-left: 10px;
            }
            
            li{
                display: block;
                margin-bottom: 5px;
                
                a, a:not([href]), a:visited, a:link {
                    color: $font-color;
                    display: flex;
                    font-size: 16px;
                    cursor: pointer;
                    font-weight: 700;
                    i{
                        flex: 0 0 20px;
                        .arrow{
                            width: 12px;
                            height: 12px;
                        }
                    }
                    span {
                        flex: 0 0 50px;
                        margin-right: 20px;
                        width: 18px;
                        height: 18px;
                        display: block;
                        margin: 1px 10px 1px 0;
                        font-size: 14px;

                        &.yellow{
                            background: #ffff00;
                        }
                        &.pink{
                            background: #ff00ff;
                        }
                        &.green{
                            background: #00ff00;
                        }
                        &.blue{
                            background: #00ffff;
                        }
                        &.marking{
                            flex: 0 0 20px;
                        }
                        &.ninchar{
                            /*margin-right: 0;*/
                            display: inline-block;
                        }
                    }
                    strong {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        flex-grow: 2;
                        &.marked{
                            font-weight: 400;
                        }
                    }
                    &.chapter{
                        margin: 5px 0 5px 0;
                    }
                    .del-marking{
                        height: 20px;
                        flex: 0 0 20px;
                        display: none;
                        fill: $color-darkgrey;
                    }
                }
                a:hover {
                    .del-marking{
                        display: block;
                    } 
                }
            }
            p{
                margin-left: 50px;
                display: none;
                font-style: italic;
            }
        }
        .notations-filter{
            margin-bottom: 5px;
            span{
                vertical-align: middle;
                margin-right: $space;
            }
            a{
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: $space - 2px;
                background: $color-grey;
                cursor: pointer;
                vertical-align: middle;
                
                &.yellow{
                    background: #ffff00;
                }
                &.pink{
                    background: #ff00ff;
                }
                &.green{
                    background: #00ff00;
                }
                &.blue{
                    background: #00ffff;
                }
                
                &.selected{
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNjcxIDU2NnEwIDQwLTI4IDY4bC03MjQgNzI0LTEzNiAxMzZxLTI4IDI4LTY4IDI4dC02OC0yOGwtMTM2LTEzNi0zNjItMzYycS0yOC0yOC0yOC02OHQyOC02OGwxMzYtMTM2cTI4LTI4IDY4LTI4dDY4IDI4bDI5NCAyOTUgNjU2LTY1N3EyOC0yOCA2OC0yOHQ2OCAyOGwxMzYgMTM2cTI4IDI4IDI4IDY4eiIvPjwvc3ZnPg==");
                    background-repeat: no-repeat;
                    background-position: right 5px top 5px;
                    background-size: 15px 15px;
                }
                &:last-of-type{
                    margin-right: 0;
                }
            }
        }
    }
}


.note{
    margin-bottom: 5px;
    
    h3{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        
        .marking{
            float: left;
            width: 18px;
            height: 18px;
            display: block;
            margin: 1px 10px 1px 0;
            
            &.yellow{
                background: #ffff00;
            }
            &.pink{
                background: #ff00ff;
            }
            &.green{
                background: #00ff00;
            }
            &.blue{
                background: #00ffff;
            }
        }
    }
    p {
        margin-left: 28px;
        display: none;
        font-style: italic;
    }
}
/*
    .multiswitch{
        ul{
            display: none;
            width: 300px;
            position: absolute;
            left: 60px;
            top: 0;
            background: #fff;
            z-index: 99;
            box-shadow: 12px 3px 12px -8px rgba(0,0,0,0.5);

            li{
                list-style: none;
                a {
                    padding: $space/2 $space;
                    margin: 0;
                    width: auto;
                    height: auto;

                    &:hover{
                        background: #e5e5e5;
                    }
                }
            }
        }
*/