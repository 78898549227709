* {
    box-sizing: border-box;
    @include reset_spaces;
}
html{
    height: 100%;
    width: 100%
}
main{
    margin: $space;
    transition: margin-left .2s ease;
}
.loading{
    width: 100%;
    text-align: center;
    padding: 50px;
    
    img {
        width: 100px;
    }
    span{
        display: block;
        text-align: center;
    }
}
.contentupdate{
    width: 50%;
    text-align: center;
    padding: 50px;
    border:3px solid $color-green;
    margin: auto;
}
.dockupdate{
    width: 100%;
    text-align: center;
    padding: $space;
    border:3px solid $color-green;
}
/*
b, strong{
    font-family: $font-bold;
    font-weight: normal;
}

.center{
    margin: auto;
}

.mt-middle{
    margin-top: 50%;
    margin-top: calc(50vh - 150px);
}
pre{
    margin: $space;
    font-size: 12px;
    max-width: 100%;
}

.tmp_src{
    border: solid 1px #666;
    width: 90%;
    padding: 5px 10px;
    font-size: 16px;
}
*/



/*
.col-centered{
    margin: auto;
}
.container-fluid {
    padding-left: 60px;
    padding-right: 60px;
}
a, a:link, a:visited, a:active{
    color: $default-link-color;
}
a:hover{
    color: lighten($default-link-color, 30);
}
.img-circle {
    border-radius: 50% !important;
    background: $highlight-color1;
    background-size: cover;
    background-position: center center;
}
.mr {
    margin-right: $space;
}
.ml {
    margin-left: $space;
}
.mb {
    margin-bottom: $space;
}
.mt {
    margin-top: $space;
}
*/
