body.product{
    @include reset_spaces;
    @include text;
    background: $color-grey;
    font-family: $font-light;
    font-size: $font-size;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
}