.search-field{
    border: solid 1px #666;
    width: 90%;
    padding: 5px 10px;
    font-size: 16px;
}
#search-global{
    .search-field{
        display: inline-block;
        width: 300px;
        vertical-align: middle;
        padding: 7px 10px;
        font-size: 18px;
    }
}

/*
.dvgui-checkbox {
        position:relative;
        margin-top: 10px;
        
        &:first-child{
            margin-top: 8px;
        }

        ~ label {        
            top: 0; 
            font-size: 13px;            
            color: $color-green;  

            width: 100%;
            position: absolute;  
            left: 0;
        }

        // Inline checkbox

        &.md-checkbox-inline {
          display: inline-block;    
        }

        .form-inline &.md-checkbox-inline {
          margin-right: 20px;
          top: 3px;
        }

        input[type=checkbox]   { 
            visibility: hidden;
            position: absolute;   
        }

        label {   
            cursor:pointer; 
            padding-left: 30px;  
        }

        label > span { 
            display:block;    
            position:absolute; 
            left:0; 
            -webkit-transition-duration:0.3s; 
            -moz-transition-duration:0.3s; 
            transition-duration:0.3s;
        }

        label > span.inc   { 
          background: #fff;
          left:-20px;  
          top:-20px;
          height:60px;
          width:60px;
          opacity:0; 
          border-radius:50% !important; 
          -moz-border-radius:50% !important; 
          -webkit-border-radius:50% !important;
        }

        label > .box {
          top:0px;
          border:1px solid $color-darkgrey;
          height:20px;   
          width:20px;
          z-index:5;
          -webkit-transition-delay:0.2s; -moz-transition-delay:0.2s; transition-delay:0.2s;
        }

        label > .check {
          top: -4px;
          left: 6px;
          width: 10px;
          height: 20px;
          border:2px solid $color-green;  
          border-top: none;
          border-left: none;
          opacity:0; 
          z-index:5;
          -webkit-transform:rotate(180deg); 
          -moz-transform:rotate(180deg); 
          transform:rotate(180deg);
          -webkit-transition-delay:0.3s; 
          -moz-transition-delay:0.3s; transition-delay:0.3s;
        }

        label > span.inc {
          -webkit-animation   :growCircle 0.3s ease;
          -moz-animation      :growCircle 0.3s ease;
          animation           :growCircle 0.3s ease;   
        }

        input[type=checkbox]:checked ~ label > .box { 
          opacity:0;
          -webkit-transform   :scale(0) rotate(-180deg);
          -moz-transform        :scale(0) rotate(-180deg);
          transform                 :scale(0) rotate(-180deg);
        }

        input[type=checkbox]:checked ~ label > .check {
          opacity:1; 
          -webkit-transform   :scale(1) rotate(45deg);
          -moz-transform      :scale(1) rotate(45deg);
          transform           :scale(1) rotate(45deg);
        }

        // Disabled state
        input[type=checkbox]:disabled ~ label,
        input[type=checkbox][disabled] ~ label {
            cursor: not-allowed;   
            opacity:0.5; 
        }

        input[type=checkbox]:disabled ~ label > .box,
        input[type=checkbox][disabled] ~ label > .box { 
            cursor: not-allowed;
            opacity: 0.5;
        }

        input[type=checkbox]:disabled:checked ~ label > .check, 
        input[type=checkbox][disabled]:checked ~ label > .check {           
            cursor: not-allowed;
            opacity: 0.5;
        } 
    }
@-webkit-keyframes growCircle {
  0%, 100%   { -webkit-transform:scale(0); opacity: 0.5}
  70%        { background: $color-green; -webkit-transform:scale(1.25); }
}
@-moz-keyframes growCircle {
  0%, 100%   { -moz-transform:scale(0); opacity: 0.5}
  70%        { background: $color-green; -moz-transform:scale(1.25); }
}
@keyframes growCircle {
  0%, 100%   { transform:scale(0); opacity: 0.5}
  70%        { background: $color-green; transform:scale(1.25); }
}
*/