.hamburger{
    width: 35px;
    height: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 999;
        
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $color-darkgrey;
        border-radius: 5px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),&:nth-child(3) {
            top: 10px;
        }
        &:nth-child(4) {
            top: 20px;
        }
    }
    &.open{
        span{
            background: $color-white;
            
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }  
}
