/*** main *********************************************************************/
$font-light:                            'UniversLTPro-45Light', sans-serif;
$font-standard:                         'UniversLTPro-55Roman', sans-serif;
$font-bold:                             'UniversLTPro-65Bold', sans-serif;

$font-color:                            #333333;
$font-size:                             16px;
$line-height:                           20px;

$space:                                 20px;

/*** colors *******************************************************************/
$color-green:                           #88bd24;
$color-darkgrey:                        #666;
$color-grey:                            #c9c8c5;
$color-midgrey:                         #e5e5e5;
$color-white:                           #fff;
$color-black:                           #000;
$color-lightgrey:                       #fafafa;
$color-darkgreen:                       #009900;
$color-yellow:                          #ff9933;
$color-blue:                            #0043ff;
$color-brown:                           #e0a672;
$color-darkbrown:                       #664a32;


