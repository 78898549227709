// NIN 2020

body.product{
    .layout5{
        .content-box{
            margin-bottom: 10px;
            display: flex;

            .content-left{
                flex: 0 0 100px;
                
                .newchapter{
                    width: 24px;
                    height: 40px;
                    margin-top: 5px;
                }

                .content-nr{
                    display: block;
                    border-bottom: solid 1px #000;
                    margin-right: 20px;
                    position: relative;

                    &.h1, &.h2{
                        font-size: 20px;
                    }
                    &.h3, &.h4{
                        font-size: 18px;
                    }
                    &.h5, &.h6, &.h7{
                        font-size: 16px;
                    }
                    &.sub-right{
                        text-align: right;
                        border: 0;
                        font-size: 16px;
                        font-family: $font-standard;
                        font-weight: normal;
                    }
                    &.betoggler-single{
                        text-align: right;
                        border: 0;

                        &:after {
                            left: 100%;
                            top: 50%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-color: rgba(204, 204, 204, 0);
                            border-left-color: #ccc;
                            border-width: 13px;
                            margin-top: -15px;
                            margin-left: 5px;
                        }
                        &.selected{
                            &:after {
                                top: 100%;
                                right: 0;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-color: rgba(204, 204, 204, 0);
                                border-top-color: #ccc;
                                border-width: 13px;
                                margin-left: -26px;
                                margin-top: 0;
                            }
                        }

                        svg{
                            height: 25px;
                            width: 25px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .content-inner{
                /***** DIRTY ********/
                #tqi1565137257497{
                    width: 18px;
                }
                #tqi1565137386673{
                    width: 18px;
                }
                #tqi1655885482360{
                    width: 18px;
                }
                #tqi1655885607090{
                    width: 18px;                    
                }
                .kappa{
                    width: 20px;
                }
                /********************/
                .backlinks{
                    margin: $space 0;
                    a{
                        display: block;
                    }
                }
                h1, h2, h3, h4, h5, h6, h7 {
                    padding-top: 0;
                    /*padding-bottom: 0;*/
                }
                h1, h2{
                    font-size: 20px;
                }
                h3, h4{
                    font-size: 18px;
                }
                h5, h6, h7{
                    font-size: 16px;
                }
                .chapternumber {
                  display: none;
                }
                &.is-be{
                    height: 0;
                    overflow: hidden;
                    &.be-show{
                        height: auto;
                    }
                }
                /*
                .anm_box{
                    font-style: italic;
                    padding: 20px;
                    margin: 20px 0;
                    border-bottom: solid 1px #000;
                    border-top: solid 1px #000;

                    .anm_tit{
                        font-style: normal;
                        font-family: $font-bold;
                        font-weight: normal;
                        margin-bottom: 10px;
                    }
                }

                .box_grey{
                    padding: 20px;
                    margin: 0 0 20px 0;
                    background: #e5e5e5;
                }
                .pagebreak{
                    display: none;
                }
                .img_20{ width:20% !important; height:auto !important; }
                .img_25{ width:25% !important; height:auto !important; }
                .img_30{ width:30% !important; height:auto !important; }
                .img_50{ width:50% !important; height:auto !important; }
                .img_75{ width:75% !important; height:auto !important; }
                .img_100{ width:100% !important; height:auto !important; }

                table{
                    width: 100%;
                    border-collapse: collapse;
                    border: 0;
                    
                    &.table_border{
                        border: solid 1px #000;
                    }
                    &.table_75{
                        width: 75%;
                    }

                    td {
                        padding: 5px 10px;
                    }
                }
                .normaltext{
                    border: solid 1px #000;                
                }*/
            }
        }
        /*
        ul{
            list-style: 'none'; 
            padding-left: $space;
            margin-bottom: $space/2;
            
            li {
                position: relative;

                &:before {
                    content: '–';
                    margin-left: -$space;
                    width: 20px;
                    display: inline-block;
                }
            }
        }

        ol {
            padding-left: $space;
            margin-bottom: $space/2;

            &.list_abc{
                counter-reset: list;
                li {
                    list-style: none;
                    counter-increment: section;
                    &:before {
                        content: counter(section, lower-alpha) ") ";
                        margin-left: -$space;
                        width: 20px;
                        display: inline-block;
                    }
                }
            }
        }
        */

    }
}