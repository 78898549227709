.highlightable{
    mark{
        &.yellow{
            background: #ffff00;
        }
        &.pink{
            background: #ff00ff;
        }
        &.green{
            background: #00ff00;
        }
        &.blue{
            background: #00ffff;
        }
        &.searchresult{
            background: $color-brown;
        }
    }
    .searchresult{
        background: #cccccc;
    }
    .highlighted{
        cursor: pointer;
    }
}
#note-tooltip{
    display: none;
    position: absolute;
    z-index: 1500;
    min-width: 200px;
    min-height: 100px;
}
#note-tooltip div{
    padding: 5px;
    background: #999;
    box-shadow: 0 5px 5px #ccc;
    -moz-box-shadow: 0 5px 5px #ccc;
    -webkit-box-shadow: 0 5px 5px #ccc;
    border-radius: 2px;
    color: #333;
    overflow: hidden;
}
#note-tooltip p, .dvForm_imagecheck p{
    display: block;
    max-width: 300px;
}
#note-tooltip div label , .dvForm_imagecheck label{
    width: 120px;
    display: block;
    float: left;
}
#note-tooltip textarea{
    width: 200px;
    height: 100px;
    border: solid 1px #333;
    padding: 5px;
}
#note-tooltip a{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 5px;
    
    .note-tooltip-icon{
        fill: #fff;
        width: 20px;
        height: 20px;
        
        &.pin{
            position: absolute;
            top: 5px;
            right: 5px;
        }
   }
}
#note-tooltip.is-pinned a{
    .note-tooltip-icon{
        &.pin{
            fill: $color-black;
        }
    }
}