.btn{
    display: inline-block;
    padding: 5px 15px;
    background: $color-green;
    border: solid 1px $color-green;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    
    &:hover{
        background: lighten($color-green, 15%);
        text-decoration: none;
    }
    
    &.btn-grey{
        background: $color-grey;
        border: solid 1px $color-grey;
        &:hover{
            background: lighten($color-grey, 15%);
            text-decoration: none;
            color: $color-darkgrey
        }
    }
    &.btn-light{
        background: none;
        color: $color-darkgrey;
            
        &:hover{
            background: lighten($color-grey, 15%);
            text-decoration: none;
            color: $color-green
        }
    }
    &.mt{
        margin-top: $space;
    }
}