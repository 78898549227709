// Werkvorschriften

// 170mm = 100%
// 1.7mm = 1%

body.product{
    .layout20{
        
        .content-box{
            /*max-width: 1200px;*/
            .pagebreak{
                padding-bottom: $space;
                clear: both;
            }
        }
        h1{
            display: none;
        }
        h2{
            font-size: 22px;
        }
        h3, h4{
            font-size: 20px;
        }
        h5, h6, h7{
            font-size: 16px;
        }
        
        #img_title {
                width:100%;
        }
        #tit_top {
                font-size:18px;
                line-height: 22px;
                margin-top:$space;
        }
        #titlepage_title {
                font-family: $font-bold;
                font-size:30px;
                line-height: 35px;
                margin-top:$space*2;
                margin-bottom:$space*2;
        }
        #titlepage_con {
                font-size:18px;
                line-height: 22px;
        }
        #tit_small {
                font-size:12px;
                line-height: 18px;
                margin-top:75px;
        }
        #vse_txt {
                width:250px !important;
                margin-top:75px;
                margin-left: -2px;
        }
        #vse {
                width:100px;
                float: right;
                margin-top: -100px;
        }
        #tit_footer {
                margin-top:$space;
        }
        
        .content-inner{
            &.notitle{
                h2{
                    display: none;
                }
            }
        }

        
        
        
        
        
        
        
        
        
        
        
        
        

        /* Table of Pictures,  Table of Table */
        .toppagenr { float:right; }
        .toppagenr::after { content: target-counter(attr(href), page); }
        .totpagenr { float:right; }
        .totpagenr::after { content: target-counter(attr(href), page); }

        .topleft, .totleft {
                /*border-bottom:0.3mm dotted #000;*/
                position: relative;
                height:$space;
                margin-bottom:$space/2;
                page-break-after: avoid;
                display: block;
                page-break-inside:avoid;
        }
        .topleft_name, .totleft_name{
                position:absolute;
                left:0mm;
                top:0mm;
                background: #fff;
                height:5mm;
                padding-right:1mm;
                max-width:145mm;
                overflow: hidden;
        }
        .toppagenr, .totpagenr {
                color:#000;	
                text-decoration: none;
                float:right; 
                width:auto;
                padding-left:1mm;
                text-align: right;
                padding-top:1.1mm;
                background: #fff;
        }
        .content.attachment{
                width: 165mm;
                margin-left: 0mm;	
        }
        .blackborder_left, .blackborder_left_block {
                display: inline-block;
                border-left: 0.3mm solid #000;

                margin-left: -7mm;
                padding-left: 6mm;
        }
        .blackborder_left_spacing {
                display: inline-block;
                border-left: 0.3mm solid #000;
                margin-left: -7mm;
                padding-left: 6mm;
                padding-bottom:1mm;	
        }
        

        .small_text {
          font-size: 12px;
          font-weight: lighter;
        }
        .text_abstand {
          margin-left: $space;
          text-indent: -4.5mm;
        }
        ol {
          margin-left: 1.5mm !important;
        } 
        .rotate_90 {
          max-width: 50px;
          height: 170px;
          padding-bottom: $space !important;
          text-align: inherit;
        }
        .rotate_90 div {
          font-family: $font-light;
          white-space: nowrap;
          transform: rotate(-90deg);
          transform-origin: 26px;
          margin-top: 140px;
          float: left;
        }
        .rotate_90.rot_2 div {
          transform-origin: 19px;
          margin-left: 10px;
        }
        .rotate_90.rot_3 div {
          transform-origin: 13px;
          margin-left: 15px;
        } 
        .table_border th {
            background: $color-grey;	
            padding: 5px;
        }

        /*
        // tables 
        table.table_25 {
          width: 50mm !important;
          font-family: $font-light;
          font-size: 14px;
        }
        table.table_50 {
          width: 73mm !important;
          font-family: $font-light;
          font-size: 14px;
        }
        table.table_75 {
          width: 110mm !important;
          font-family: $font-light;
          font-size: 14px;
        }
        table.table_100 {
          width: 110mm !important;
          font-family: $font-light;
          font-size: 14px;
        }
        .td_50 {
          width: 50px;
          font-family: $font-light;
          font-size: 14px;
        }
        .table_border {
          width: 100% !important;
          font-family: $font-light;
          border-bottom: 1px solid #000 !important;
        }
        .table_border td, .table_border th {
          border: 1px solid black;
          border-collapse: collapse;
        }
        .table_border {
          border: none;
          border-collapse: collapse;
        }

        .table_border td {
          padding: 1mm;
          line-height: 20px;
        }
        .table_border th {
                background:#d9d9d9;	
                padding: 1mm;
          line-height: 20px;
        }
        .table_legende {
          line-height: 20px;
        }
        .td_noborder {
          border-left: none !important;
          border-right: none !important;
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
          font-size: 14px;
        }
        .td_noborder:first-child {
          border-left: 1px solid #000 !important;
          font-family: $font-light;
          font-size: 14px;
        }
        .td_noborder_ver {
          border-left: none !important;
          border-right: none !important;
          font-family: $font-light;
          font-size: 14px;
        }
        .td_noborder_top, .td_noborder_bottom {
          font-family: $font-light;
          font-size: 14px;
        }
        .td_noborder_hor {
          border-top: none !important;
          border-bottom: none !important;
          font-family: $font-light;
          font-size: 14px;
        }
        .anm_box {
          border-top: 2px solid #898a8d;
          border-bottom: 2px solid #898a8d;
          font-family: $font-light;
          padding: $space;
          font-style: italic;
          margin-top: $space;
          margin-bottom: $space;
        }
        .anm_box .ninchar {
          font-style: normal;
        }
        .anm_box .anm_tit {
          font-style: normal;
          font-family: $font-bold;
          margin-bottom: $space/2;
        }
        .anm_box li {
          font-family: $font-light;
          margin-bottom: 0px;
          line-height: 26px;
        }
        .anm_box ul {
          margin-left: $space !important;
        }
        .anm_line {
          width: 69mm;
          height: 0.5px;
          background: #898a8d;
          margin-top: $space;
          margin-bottom: $space;
        }
        .normaltext {
          font-family: $font-light;
          line-height: 22px;
          font-size: 16px !important;
          margin-left: $space;
        } 

        .normaltext tr td:first-child {
          width: 5mm;
        }
        .second_table {
          line-height: 15.5pt;
        }
        .second_table tr td:first-child {
          width: 13mm;
          padding-right: 1mm;
          text-align: right;
        }
        .chapteranchor {
          height: 0px !important;
          display: block !important;
        }
*/

        #chrono_footer {
                position:static;
                top:180mm;
                padding-top:5mm;
                border-top: 1px solid #000;
        }
        .attachment {
                page:attachment;
        }
        .attachment h2 {
                display: none;	
        }
        /* global */ 
        /*
        p {
          font-size: 16px;
          margin-bottom: 0.1em;
          margin-top: 0.1em
        }
        td {
          vertical-align: top
        }
        body {
          font-size: 16px;
          line-height: 22px;
          font-family: $font-light;
        }
        strong, b {
          font-family: $font-bold;
          font-weight: bold;
        }
        h1 {
          font-family: $font-bold;
          font-size: 22px;
          font-weight: bold;
          padding-bottom: $space/2;
          padding-top: 0.3mm;
          line-height: 16pt;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h2 {
          font-family: $font-bold;
          font-size: 22px;
          font-weight: bold;
          padding-bottom: $space/2;
          padding-top: 0.3em;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h3 {
          font-family: $font-bold;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: $space/2;
          padding-top: 0.3em;
          margin: 0;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h4 {
          font-family: $font-bold;
          font-size: 20px;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: $space/2;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h5 {
          font-family: $font-bold;
          font-size: 16px;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: $space/2;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        h6 {
          font-family: $font-bold;
          font-size: 16px;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: $space/2;
          display: block;
          width: 100%;
          page-break-after: avoid;
        }
        table { position: relative;
          font-weight: normal;
          width: 100% !important;
          border: none;
        }
        table td {
          border: none;
        }
        
        body > h1 {
          padding-bottom: 0.1em;
        }
        img {

          max-width: 100%;
          height: auto !important;
        }
        ul li {
          page-break-inside: avoid;
        }
        ul > li:before {
          content: '–';
          position: absolute;
          margin-left: -2.5mm;
        }
        ul {
          margin-left: 0px !important;
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        ul, ol {
          padding-left: $space/2;
          margin-bottom: 0.5em;
          max-width: 100%;
          margin-top: 0.1em;
          line-height: 22px !important;
          margin-left: $space !important;
        }
        ul li, ol li {
          margin-bottom: 0px !important;
        }
        ul.list_std > li:before {
          content: '–';
          position: absolute;
          margin-left: -2.5mm;
        }
        ol.nodot { 
            counter-reset: item;
            list-style-type: none;
        }
        ol.nodot li { display: block; }
        ol.nodot li:before { 
            content: counter(item) "  "; 
            counter-increment: item 
        }
        a { 
            text-decoration: underline;
          color: #0001FA;
          display: inline-block;
        }
        .header {
          position: fixed;
          top: -1cm;
          left: 0px;
          right: 0px;
        }
        .header .hcontent {
          text-align: right;
          margin-top: -5mm;
        }
        .ninchar {
          font-family: 'NIN3X';
          display: inline;
          margin-top: 0em;
          margin-right: 0.2em;
        }
        .pagebreak {
          page-break-after: always;
        }
        .chapternumber {
          vertical-align: top;
          display: inline-block;
          width: 10mm;
          margin-right: $space/2;
        }
        .chaptertitle {
          display: inline-block;
          width: 148mm;
          margin-left: $space/2;
        }
        .chapterwrapper {
          orphans: 3;
          widows: 3;
                page-break-inside: avoid;
        }
        
        .row_number {
          display: block;
          font-weight: bold;
          width: 19mm;
          vertical-align: top;
          position: absolute;
          text-align: right;
        }
        .chapterwrapper.dontsplit .chapternumber {
                display: none;	
        }
        .chapterwrapper.dontsplit .chaptertitle {
                width:165mm;
                margin-left:0mm;
        }
        // table of contents 
        .tocpagenr { float:right; }
        .tocpagenr::after { content: target-counter(attr(href), page); }

        .content_toc, .content_top, .content_tot {
                width: 165mm !important;
                margin-left:0mm !important;
                position: relative;
                margin-bottom:10mm;
                box-decoration-break: clone;

        }
        .tocleft, .tocsubleft {
                font-family: 'HelveticaNeueLT Pro 65 Md';
                border-bottom:0.3mm dotted #000;
                position: relative;
                height:$space;
                margin-bottom:$space/2;
                page-break-after: avoid;
                display: block;
                page-break-inside:avoid;
        }
        .tocsubleft {
         font-family: $font-light;
                page-break-after:auto;
        }
        .tocleft_num, .tocsubleft_num{
                position:absolute;
                left:0mm;
                top:0mm;
                background: #fff;
                height:5mm;
                padding-right:1mm;
                width:10mm;
                overflow: hidden;
        }

        .tocleft_name, .tocsubleft_name{
                position:absolute;
                left:10mm;
                top:0mm;
                background: #fff;
                height:5mm;
                padding-right:1mm;
                max-width:145mm;
                overflow: hidden;
        }
        .tocpagenr {
                color:#000;	
                text-decoration: none;
                float:right; 
                width:auto;
                padding-left:1mm;
                text-align: right;
                background: #fff;
                height: 5mm;
        }
        sup, sub {
          font-size: 6pt !important;
        }
        .img_25 {
          width: 25% !important;
          height: auto !important;
        }
        .img_30 {
          width: 30% !important;
          height: auto !important;
        }
        .img_50 {
          width: 50% !important;
          height: auto !important;
        }
        .img_75 {
          width: 75% !important;
          height: auto !important;
        }
        .img_100 {
          width: 100% !important;
          height: auto !important;
        }
        .abstand_nach {
          position: relative;
          display: block;
          padding-bottom: $space/2;
          margin-bottom: 0px;
        }
        .abstand_vor {
          position: relative;
          display: block;
          padding-top: $space/2;
          margin-bottom: 0px;
        }
        .blocktitle {
          font-size: 16px;
          font-weight: bold;
          padding-top: 0.3em;
          padding-bottom: 0.3em;
          display: block;
        }
        .blocktitle_i {
          font-size: 16px;
          font-weight: bold;
          font-style: italic;
          padding-top: 0.3em;
          padding-bottom: 0.3em;
          display: inline-block;
        }
        .list_abc {
          list-style-type: none;
          padding-left: 5.5mm;
          font-family: $font-light;
        }
        .list_abc li:before {
          content: counter(section, lower-alpha) ") ";
          margin-right: 1mm;
          margin-left: -4.5mm;
        }
        .list_abc > li {
          counter-increment: section;
          text-indent: 0mm;
          margin-bottom: 0px;
        }
*/
    }
}
