.map-preview{
    position: relative;
    background: #fff;
    border: solid 1px #ccc;
    width: 100%;
    margin-bottom: 10px;
    
    img {
        width: 100%;
        width: calc(100% - 20px);
        margin: 10px;
    }
}
#map-layer{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 900;
    
    #map-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 10;
    }
    .map-close{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 50;
        background: $color-green;
        width: 40px;
        height: 40px;
        display: block;
        cursor: pointer;
        
        svg{
            width: 20px;
            height: 20px;
            fill: #fff;
            margin: 10px;
        }
    }
}
.maps-tooltip-icon{
    height: 16px;
    width: 16px;
    fill: $color-darkgrey;
    vertical-align:middle;
    margin-left: 5px;
}
.leaflet-right {
    .leaflet-control {
        margin-right: 20px;
        margin-top: 80px;
    }
} 

.leaflet-touch{
    .leaflet-bar{
        border: 0;
        border-radius: 0;
            
        a {
            &:first-child {
                border: 0;
                border-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            border: 0;
            background: $color-green;
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 1px;
            font-size: 30px;
            line-height: 40px;
            vertical-align: middle;
            
            &.leaflet-disabled{
                color: #fff;
                background: #ccc;
            }
        }
    }
} 
.leaflet-control-zoom-in{
    background: $color-green;
    width: 40px;
    height: 40px;
    display: inline-block;
}